import { Button, IconButton } from '@mui/material';
import variables from '../../utils/variables';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import {
    aminoSignTx,
    initializeChain,
    initializeLeap,
    setAccountAddress,
    setDisconnect,
} from '../../actions/account/wallet';
import withRouter from '../../components/WithRouter';
import { connect } from 'react-redux';
import { getWrapAddress } from '../../utils/strings';
import CopyButton from '../../components/CopyButton';
import copyIcon from '../../assets/copy.svg';
import { config } from '../../config';
import { connectBCAccount, fetchProfileDetails, setUpdateName, verifyAccount } from '../../actions/account';
import { fetchBalance } from '../../actions/account/BCDetails';
import { showConnectDialog } from '../../actions/navBar';
import EditIcon from '@mui/icons-material/Edit';
import UpdateNameTextField from './UpdateNameTextField';

class KeplrConnect extends Component {
    constructor (props) {
        super(props);

        this.initKeplr = this.initKeplr.bind(this);
        this.initializeKeplr = this.initializeKeplr.bind(this);
        this.initializeLeap = this.initializeKeplr.bind(this);
        this.initLeap = this.initLeap.bind(this);
        this.handleDisconnect = this.handleDisconnect.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    componentDidMount () {
        if (this.props.address === '' && localStorage.getItem('ccc_live_stream_address')) {
            if (localStorage.getItem('ccc_live_leap')) {
                setTimeout(() => {
                    this.initializeLeap();
                }, 600);

                return;
            }

            // used set time out to omit the image loading issue with window.onload
            setTimeout(() => {
                this.initializeKeplr();
            }, 600);
        } else {
            if ((this.props.address) && this.props.balance &&
                (this.props.balance.length === 0) && !this.props.balanceInProgress) {
                this.props.fetchBalance(this.props.address);
            }
        }

        if (!Object.keys(this.props.profileDetails).length && !this.props.profileDetailsInProgress) {
            this.props.fetchProfileDetails();
        }

        if (localStorage.getItem('ccc_live_leap')) {
            window.addEventListener('leap_keystorechange', () => {
                this.props.setDisconnect();
                this.initLeap();
                localStorage.removeItem('acToken_of_ccc_live_stream');
                localStorage.removeItem('ccc_live_stream_address');
            });
        } else {
            window.addEventListener('keplr_keystorechange', () => {
                this.props.setDisconnect();
                this.initKeplr();
            });
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth) && !this.props.inProgress) {
            this.props.fetchProfileDetails();
        }
    }

    componentWillUnmount () {
        window.removeEventListener('keplr_keystorechange', this.initKeplr);
        window.removeEventListener('leap_keystorechange', this.initLeap);
    }

    initializeKeplr () {
        this.props.initializeChain((address) => {
            if (!address) {
                window.onload = () => this.initializeKeplr();
                return;
            }

            this.props.setAccountAddress(address[0].address);
            this.props.fetchProfileDetails();
            if ((address && address.length && address[0] && address[0].address) &&
                (this.props.balance.length === 0) && !this.props.balanceInProgress) {
                this.props.fetchBalance(address[0].address);
            }
        });
    }

    initKeplr () {
        this.props.initializeChain((address) => {
            if (address.length && address[0] && address[0].address) {
                const data = {
                    bcAccountAddress: address[0].address,
                };
                this.props.connectBCAccount(data, (res) => {
                    if (res) {
                        if (window.keplr) {
                            window.keplr.defaultOptions = {
                                sign: {
                                    preferNoSetFee: true,
                                    preferNoSetMemo: true,
                                },
                            };
                        }
                        const tx = {
                            msg: {
                                type: 'omniflix/MsgSign',
                                value: {
                                    address: address[0].address,
                                },
                            },
                            fee: {
                                amount: [{
                                    amount: String(0),
                                    denom: config.COIN_MINIMAL_DENOM,
                                }],
                                gas: String(1),
                            },
                            preferNoSetFee: true,
                            memo: res['auth_code'],
                        };

                        this.props.aminoSignTx(tx, address[0].address, (result) => {
                            if (result) {
                                const data = {
                                    authCode: res['auth_code'],
                                    sign: result.signature,
                                };

                                this.props.verifyAccount(res._id, data, (error) => {
                                    if (!error) {
                                        localStorage.setItem('ccc_live_stream_address', address[0].address);
                                        this.props.fetchBalance(address[0].address);
                                        this.props.fetchProfileDetails();
                                        this.props.setAccountAddress(address[0].address);
                                        if (window.keplr) {
                                            window.keplr.defaultOptions = {};
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    initLeap () {
        this.props.initializeLeap((address) => {
            if (address.length && address[0] && address[0].address) {
                const data = {
                    bcAccountAddress: address[0].address,
                };
                this.props.connectBCAccount(data, (res) => {
                    if (res) {
                        if (window.leap) {
                            window.leap.defaultOptions = {
                                sign: {
                                    preferNoSetFee: true,
                                    preferNoSetMemo: true,
                                },
                            };
                        }
                        const tx = {
                            msg: {
                                type: 'omniflix/MsgSign',
                                value: {
                                    address: address[0].address,
                                },
                            },
                            fee: {
                                amount: [{
                                    amount: String(0),
                                    denom: config.COIN_MINIMAL_DENOM,
                                }],
                                gas: String(1),
                            },
                            preferNoSetFee: true,
                            memo: res['auth_code'],
                        };

                        this.props.aminoSignTx(tx, address[0].address, (result) => {
                            if (result) {
                                const data = {
                                    authCode: res['auth_code'],
                                    sign: result.signature,
                                };

                                this.props.verifyAccount(res._id, data, (error) => {
                                    if (!error) {
                                        localStorage.setItem('ccc_live_stream_address', address[0].address);
                                        localStorage.setItem('ccc_live_leap', 'leap');
                                        this.props.fetchBalance(address[0].address);
                                        this.props.fetchProfileDetails();
                                        this.props.setAccountAddress(address[0].address);
                                        if (window.leap) {
                                            window.leap.defaultOptions = {};
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    initializeLeap () {
        this.props.initializeLeap((address) => {
            if (!address) {
                window.onload = () => this.initializeLeap();
                return;
            }

            localStorage.setItem('ccc_live_stream_address', address && address.length && address[0] && address[0].address);
            if ((address && address.length && address[0] && address[0].address) &&
                (this.props.balance.length === 0) && !this.props.balanceInProgress) {
                this.props.fetchBalance(address[0].address);
            }
            this.props.fetchProfileDetails();
            this.props.setAccountAddress(address[0].address);
        });
    }

    handleDisconnect () {
        localStorage.removeItem('ccc_live_stream_address');
        this.props.setDisconnect();
    }

    handleEdit () {
        this.props.setUpdateName(true);
    }

    render () {
        return (
            <div className="section3">
                {this.props.address
                    ? <div className="connected_button">
                        <div className="profile_address">
                            {this.props.update
                                ? <div className="update_name">
                                    <UpdateNameTextField/>
                                </div>
                                : this.props.inProgress && this.props.address === ''
                                    ? ''
                                    : <div className="address_section">
                                        {this.props.profileDetails && this.props.profileDetails.sendbird && this.props.profileDetails.sendbird.nickname
                                            ? <span className="user_name">
                                                {this.props.profileDetails.sendbird.nickname}
                                            </span>
                                            : <div className="hash_text" title={this.props.address}>
                                                {getWrapAddress(this.props.address, 10)}
                                            </div>}
                                        <IconButton aria-label="edit" onClick={this.handleEdit}>
                                            <EditIcon/>
                                        </IconButton>
                                        <CopyButton
                                            data={this.props.profileDetails && this.props.profileDetails.sendbird && this.props.profileDetails.sendbird.nickname
                                                ? this.props.profileDetails.sendbird.nickname : this.props.address} icon={copyIcon}/>
                                    </div>}
                            <span className="profile_image"/>
                        </div>
                        <Button
                            className="disconnect_button"
                            onClick={this.handleDisconnect}>
                            {variables[this.props.lang].disconnect}
                        </Button>
                    </div>
                    : <Button
                        className="connect_button"
                        onClick={this.props.showConnectDialog}>
                        {variables[this.props.lang]['connect_with_omniflix']}
                    </Button>}
            </div>
        );
    }
}

KeplrConnect.propTypes = {
    address: PropTypes.string.isRequired,
    aminoSignTx: PropTypes.func.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    connectBCAccount: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    initializeChain: PropTypes.func.isRequired,
    initializeLeap: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    profileDetails: PropTypes.object.isRequired,
    profileDetailsInProgress: PropTypes.bool.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    setDisconnect: PropTypes.func.isRequired,
    setUpdateName: PropTypes.func.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
    update: PropTypes.bool.isRequired,
    verifyAccount: PropTypes.func.isRequired,
    auth: PropTypes.string,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        auth: state.account.token.value,
        balance: state.account.bc.balance.value,
        balanceInProgress: state.account.bc.balance.inProgress,
        lang: state.language,
        inProgress: state.account.wallet.connection.inProgress,
        profileDetails: state.account.profileDetails.value,
        profileDetailsInProgress: state.account.profileDetails.inProgress,
        update: state.account.updateName,
    };
};

const actionToProps = {
    aminoSignTx,
    connectBCAccount,
    fetchBalance,
    fetchProfileDetails,
    initializeChain,
    initializeLeap,
    setAccountAddress,
    setDisconnect,
    verifyAccount,
    showConnectDialog,
    setUpdateName,
};

export default withRouter(connect(stateToProps, actionToProps)(KeplrConnect));
